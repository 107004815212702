import Axios from './';
import {
  AdminLoginPayload,
  ListResult,
  Admin,
  AdminPara,
  Filter,
} from '../models';

export const adminLogin = async (para: AdminLoginPayload) => {
  const { data } = await Axios.post<string>('/admin/login', para);
  return data;
};

export const getAdminList = async (params: Filter) => {
  const { data } = await Axios.get<ListResult<Admin>>('/admin', {
    params,
  });
  return data;
};

export const createNewAdmin = async (para: AdminPara) => {
  const { data } = await Axios.post<Admin>('/admin', para);
  return data;
};

export const getSingleAdmin = async (adminid: string) => {
  const { data } = await Axios.get<Admin>(`/admin/${adminid}`);
  return data;
};

export const updateAdmin = async (adminid: number, para: { nick: string }) => {
  const { data } = await Axios.patch<null>(`/admin/${adminid}`, para);
  return data;
};

export const deleteAdmin = async (adminid: number) => {
  const { data } = await Axios.delete<string>(`/admin/${adminid}`);
  return data;
};

export const getProfile = async () => {
  const { data } = await Axios.get<ListResult<Admin>>('/admin/profile');
  return data;
};

export const resetAdminPassword = async (
  adminid: number,
  para: { password?: string },
) => {
  const { data } = await Axios.post<string>(
    `/admin/${adminid}/resetPassword`,
    para,
  );
  return data;
};

export const logout = async () => {
  const { data } = await Axios.post('/logout');
  return data;
};
