import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useLocation } from 'react-router-dom';

import Header from './Header';
import Navbar from './Navbar';
import Footer from './Footer';
import Rightbar from 'app/components/CommonForBoth/Rightbar';

import {
  changeLayoutWidth,
  changeTopbarThemeAction,
  changeLayoutAction,
} from 'store/Layout';

interface Props {
  children: React.ReactChild;
}

function Layout({ children }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const { showRightSidebar, isPreloader, layoutWidth, topbarTheme } =
    useSelector((rootState: RootState) => rootState.layout);

  useEffect(() => {
    if (isPreloader) {
      if (document.getElementById('preloader')) {
        document.getElementById('preloader')!.style.display = 'block';
      }
      if (document.getElementById('status')) {
        document.getElementById('status')!.style.display = 'block';
      }

      setTimeout(function () {
        if (document.getElementById('preloader')) {
          document.getElementById('preloader')!.style.display = 'none';
        }
        if (document.getElementById('status')) {
          document.getElementById('status')!.style.display = 'none';
        }
      }, 2500);
    } else {
      if (document.getElementById('preloader')) {
        document.getElementById('preloader')!.style.display = 'none';
      }
      if (document.getElementById('status')) {
        document.getElementById('status')!.style.display = 'none';
      }
    }
    // Scroll Top to 0
    window.scrollTo(0, 0);
    // let currentage = this.capitalizeFirstLetter(this.props.location.pathname)

    const title = location.pathname;
    let currentage = title.charAt(1).toUpperCase() + title.slice(2);
    document.title = `${currentage} | Skote - Responsive Bootstrap 5 Admin Dashboard`;
    dispatch(changeLayoutAction('horizontal'));
    if (topbarTheme) {
      dispatch(changeTopbarThemeAction(topbarTheme));
    }
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [layoutWidth, isPreloader, location.pathname, topbarTheme, dispatch]);

  const openMenu = () => {
    setIsMenuOpened(prev => !prev);
  };

  return (
    <>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header
          theme={topbarTheme}
          isMenuOpened={isMenuOpened}
          openLeftMenuCallBack={openMenu}
        />
        <Navbar menuOpen={isMenuOpened} />
        <div className="main-content">{children}</div>
        <Footer />
      </div>

      {showRightSidebar ? <Rightbar /> : null}
    </>
  );
}

export default Layout;
