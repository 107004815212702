/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
// Import root app
import { App } from 'app';
import * as React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// Use consistent styling
import 'sanitize.css/sanitize.css';
// Initialize languages
import './locales/i18n';
import { persistor, store } from './store';

Bugsnag.start({
  apiKey:
    process.env.REACT_APP_BUGSNAG_API_KEY || '19388f84c5fe354cc3f0961a7f14449e',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  appVersion: process.env.REACT_APP_VERSION,
  autoTrackSessions: false,
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <HelmetProvider>
        <React.StrictMode>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </React.StrictMode>
      </HelmetProvider>
    </Provider>
  </ErrorBoundary>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}
