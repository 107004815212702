import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { API_URL } from 'app/config';

export function StatisticPage() {
  const [loading, setLoading] = useState(false);
  const reloadiFrame = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>數據分析 | SPS</title>
          <meta name="數據分析" content="數據分析" />
        </Helmet>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm={12}>
                      <div className="d-flex flex-row-reverse">
                        <Button className="btn btn-info" onClick={reloadiFrame}>
                          <i className="bx bx-loader-circle" />
                          {` ${'更新數據'}`}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {loading ? (
                    <div
                      style={{
                        width: '100%',
                        height: 'calc(100vh - 130px - 24px - 70px - 37px)',
                      }}
                      className="d-flex justify-content-center"
                    >
                      <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <iframe
                      id="statistic_iframe"
                      src={API_URL + '/v1/statistic/exercise'}
                      title="appicidea home page"
                      style={{
                        width: '100%',
                        height: 'calc(100vh - 130px - 24px - 70px - 37px)',
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
