import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';

const PrivacyPolicyPage = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>「療心園」私隱權條款 | SPS</title>
        </Helmet>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div>
                          <div className="text-center">
                            <h4>「療心園」私隱權條款</h4>
                            <p className="text-muted mb-4">
                              <i className="mdi mdi-calendar me-1"></i>{' '}
                              更新於2021年9月1日
                            </p>
                          </div>

                          <hr />

                          <div className="mt-4">
                            <div className="text-muted font-size-14">
                              <p>
                                「療心園」（以下簡稱“本平台”），是一個由「生命熱線有限公司」（以下
                                簡稱為“我們”）所開發的手機應用程式，為了讓使用者（以下簡稱為“使用者”
                                或
                                “你”）能夠安心的使用本平台的各項服務與資訊，請你在使用本平台前詳
                                閱下列私隱權條款，以保障你的權益。安裝本平台即表示你明白及同意本私隱
                                權條款內容。
                              </p>

                              <h5 className="mb-3">
                                收集的個人資料類別及收集個人資料的目的
                              </h5>

                              <p className="mb-4">
                                由本平台收集個人資料全屬自願性質。我們只有在使用者同意下才會收集個人
                                資料，當中包括姓名、電郵地址﹑年齡層等資料。使用此平台時，你可以選擇
                                輸入你的真實名字去建立使用者用戶，但這不是必須，你亦可以暱稱建立使用
                                者用戶。一旦提供你的個人資料註冊本平台，即表示你同意我們就此本平台及
                                所有附帶用途使用你的個人資料。
                              </p>

                              <p>
                                本平台記錄使用者瀏覽平台次數﹑使用記錄及其他使用數據。所收集的使用者
                                性別﹑年齡層﹑使用平台次數及使用記錄會用於製作統計報告及使用成效，以助
                                我們分析及完善本平台服務。
                              </p>
                              <p className="mb-4">
                                使用者在用戶登記時可選「本人是自殺者親友，希望接相關資訊」一項，本平
                                台會根據你提供的電郵，提供相關資訊。
                              </p>

                              <h5 className="mb-3">
                                對未成年人士個人資訊的保護
                              </h5>

                              <p className="mb-4">
                                本平台適合12歲或以上人士使用。然而，未滿
                                18歲的未成年人士，應當在監護
                                人陪同和指導下閱讀本協議，並在使用服務前取得監護人的同意。如你未滿
                                18
                                歲及你的監護人不同意你使用我們的服務，請你立即终止使用我們的服務。
                              </p>

                              <h5 className="mb-3">保障措施</h5>
                              <p className="mb-4">
                                為保護本平台及你的個人資料，我們採用嚴格的保護措施。本平台的數據儲存
                                於安全的服務器上，並設有合適及必要的安全防護措施。我們將會盡一切合理
                                努力，確保在本網站所收集的個人資料均儲存及妥善保管於安全的地方。我們
                                可在本平台使用「曲奇」網絡跟蹤器(cookies)，以便使用者繼續瀏覽若干內容及
                                建立用戶的資料數據。如使用者不允許「曲奇」網絡跟蹤器的使用，使用者可
                                在其互聯網瀏覽器上進行相關設定，但這可能會限制你使用有關服務。
                              </p>
                              <p className="mb-4">
                                我們採取足夠的措施防止資料遭受未經授權的查閱、意外丟失或毀壞。只由經
                                過授權的人員才能接觸你的個人資料，相關處理人員皆簽有保密合約，如有違
                                反保密義務者，將會受到相關的法律處分。
                              </p>
                              <p className="mb-4">
                                如因服務需要有必要委託其他單位提供服務時，本平台亦會嚴格要求其遵守保
                                密義務，並且採取必要檢查程序以確定其將確實遵守。
                              </p>
                              <p className="mb-4">
                                然而，通過互聯網傳輸資料並不完全安全。雖然我們會盡最大努力保護你的資
                                料，但我們不能保證資料傳輸至有關服務的安全性；任何傳輸風險由你自己承
                                擔。一旦我們收到資料，我們將使用嚴格程序和安全功能，以試圖阻止未經授
                                權的查閱。
                              </p>

                              <h5 className="mb-3">連結網站</h5>
                              <p className="mb-4">
                                本平台載有通往其他網站的連結點。每當你啟動任何此等連結點，即已離開了
                                本網站；而你向任何其他團體提供的任何個人資料或任何其他資料，一概不在
                                我們的管控範圍內。
                              </p>
                              <p className="mb-4">
                                如欲查閱和更改在本平台提供的個人資料或查詢相關事宜，可透過電郵至
                                <a href="mailto: admin@sps.org.hk">
                                  admin@sps.org.hk
                                </a>
                                與我們聯絡。
                              </p>
                              <h5 className="mb-3">要求停止收取相關資訊</h5>
                              <p className="mb-4">
                                本平台可以免費提供相關資訊予作為自殺者親友的使用者，使用者在用戶登記
                                時可以選擇自行選取是否收取我們的相關資訊。如不欲再收取我們的相關資訊
                                ，請電郵至
                                <a href="mailto: admin@sps.org.hk">
                                  admin@sps.org.hk
                                </a>
                                ，並詳列你的登記姓名及電郵地址或登入名稱。
                              </p>
                              <h5 className="mb-3">查閱、改正及刪除個人資料</h5>
                              <p className="mb-4">
                                根據《個人資料（私隱）條例》（第486章），你有權要求查閱及改正你向本平
                                台提供的個人資料。根據私隱條例的規定，我們有權就任何個人資料的查閲或
                                更改要求收取合理的處理費用。如你要求查閱、改正或刪除個人資料，必詳列
                                出你的登記姓名及﹑電郵地址或登入方式，以便我們核實屬於你的個人資料。
                                如對保障個人資料的政策及措施或資料收集的類別有任何查詢，請電郵我們至
                                <a href="mailto: admin@sps.org.hk">
                                  admin@sps.org.hk。
                                </a>
                              </p>
                              <h5 className="mb-3">終止服務</h5>
                              <p className="mb-4">
                                使用者可以自行選擇隨時停止使用本服務。如你要求本平台在你停止使用本服
                                務後，對你的個人資訊(即姓名及電郵地址)
                                進行刪除或匿名化處理，你可發送 電子郵件至
                                <a href="mailto: admin@sps.org.hk">
                                  admin@sps.org.hk。
                                </a>
                                提出此要求。你亦必須詳列出你的登記姓名及﹑電郵
                                地址或登入方式，以便我們核實屬於你的個人資料。
                              </p>
                              <p className="mb-4">
                                由於我們的服務是隨著時間發展，我們可以在任何時候自行決定改變或停止服
                                務的全部或任何部分。如本平台的服務全面終止，我們會盡力提前向你通知，
                                並在終止服務後對你的個人資訊
                                (即姓名及電郵地址)進行刪除或匿名化處理。
                              </p>
                              <h5 className="mb-3">私隱權條款的更新</h5>
                              <p className="mb-4">
                                我們保留更改及修訂條款細則的最終決定權。我們會根據最新私隱條例規定、
                                服務發展狀况對現有條款做出修訂。我們可以在任何時候自行決定修改本條款
                                。如果有修改，我們將於平台上公佈修改後的條款或通過平台的通知功能讓你
                                知道。每當我們修改條款後，你都有責任查看最新條款。如果你在我們於平台
                                上公佈修改條款後繼續使用服務，即代表你同意接受修改後的條款約束。如果
                                你不同意接受修改後的條款約束，你就不能再使用本平台的服務。
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicyPage;
