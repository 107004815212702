import { Redirect } from 'react-router-dom';

import PrivacyPolicyPage from 'app/pages/PrivacyPolicyPage';
import { LogoutPage, LoginPage } from 'app/pages/Authentication';
import { AdminListPage, AdminFormPage } from 'app/pages/AdminPage';
import { UserListPage } from 'app/pages/UserPage';
import { HomePage } from 'app/pages/HomePage/Loadable';
import { StatisticPage } from 'app/pages/StatisticPage';
import {
  NotificationListPage,
  NotificationFormPage,
} from 'app/pages/NotificationList';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import { TakeDownDetailPage, TakeDownListPage } from "app/pages/TakeDownPage";
import { TakeDownPage } from "app/pages/TakeDownFormPage/Loadable";

interface Route {
  path: string;
  component: any;
  exact?: boolean;
}
const userRoutes: Route[] = [
  // admin
  { path: '/admins', component: AdminListPage },
  { path: '/admins/new', component: AdminFormPage, exact: true },
  { path: '/admins/edit/:adminid', component: AdminFormPage, exact: true },
  {
    path: '/admins/edit/:adminid/:password',
    component: AdminFormPage,
    exact: true,
  },
  // user
  { path: '/users', component: UserListPage },
  // statistic
  { path: '/statistic', component: StatisticPage },
  // push notification
  { path: '/notification', component: NotificationListPage },
  { path: '/notification/new', component: NotificationFormPage },

  // take down
  { path: '/take-down', component: TakeDownListPage },
  { path: '/take-down/detail/:takedownId', component: TakeDownDetailPage },

  // others
  { path: '/', component: HomePage },
  { path: '/notFound', component: NotFoundPage },
  { path: '*', component: () => <Redirect to="/notFound" /> },
];

const authRoutes: Route[] = [
  { path: '/logout', component: LogoutPage },
  { path: '/login', component: LoginPage },
  { path: '/takedown', component: TakeDownPage },
  { path: '/privacyPolicy', component: PrivacyPolicyPage },
  { path: '/notFound', component: NotFoundPage },
];

export { userRoutes, authRoutes };
